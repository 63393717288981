@use '~@kolonialno/kolibri-web/styles/utils/media-query.scss';

.IllustrationColumn {
  display: none;
  @include media-query.breakpoint(md) {
    display: initial;
  }
}

.ActionRow {
  white-space: nowrap;
}
